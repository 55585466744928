@import "bootstrap";
@import "fontawesome/fontawesome";
@import "fontawesome/brands";
@import "fontawesome/solid";
@import "fonts_inter";

img {
    margin-bottom: 24px; 
    margin-top: 17px; 
    border-radius:2%;
}


body {
    padding-top: 92px;
}

figcaption {
    text-align: right;
    margin-top: -20px;
    margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6, h7 {
    letter-spacing: -0.03em;
}



/* Top navigation bar */


p.byline {
	text-align: center;
	font-size: 18px;
	line-height: 36px;
	margin-top: -24px;
	margin-bottom: 48px;
}


firstp {
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px; /* or 950px */
}

/* Home, News */
#newsid p {
	text-align: left;
}

#homeidtmp h1 {
	text-align: center;
}

#newsid img {
	margin-top: 6px;
	margin-bottom: 6px;
}

/* Team & Publications */
#gridid img { margin: 16px 22px 6px 0; border-radius:10%; box-shadow: 2px 2px 5px #888888 }
#homeid img { margin: 0 0 0 0; border-radius:5%; box-shadow: 0px 0px 0px #888888 }
#picid img { margin: 6px 3px 60px 6px; border-radius:1%; box-shadow: 2px 2px 5px #888888 }
#gridid .row { margin-bottom: 24px; }


/* Grid overrides */
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
	padding-left: 16px;
	padding-right: 16px;
}

/* Grid overrides */
.col-sm-4 {
	padding-left: 26px;
	padding-right: 26px;
}


/* Carusel */

.carousel {
    border-radius: 3px 3px 3px 3px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

span.publi_journal {
    font-family: $headings-font-family;
    font-style: italic;
    color: #222;
}

a.publi_author { /* Internal authors */
    font-weight: 600;
    color: #0544ab;
}

span.publi_author { /* External authors */
}

a.publi_title {
    font-family: $headings-font-family;
    font-weight: bold;
    color: #0544ab;
}

.panel-footer {
    color: #eee;
}

.panel-footer a:link, .panel-footer a:active {
    color: #0594cb;
    text-decoration: none;
}

.panel-footer a:hover {
    color: #96ccff;
    text-decoration: none;
}

a {
    -webkit-transition: color .15s ease-in;
    transition: color .15s ease-in;
}

div#footer {
    border-width: 0px;
}

.footer-icons {
    font-size: 26px;
}

div#footer .footer-icons i {
    transition: transform .2s;
    opacity: 0.6;
    color: white;
}

div#footer .footer-icons i:hover {
    transform: scale(1.1);
    opacity: 0.85;
}

div.navbar div.container-fluid {
    padding: 20px 30px 20px 30px;
}

div.navbar {
    font-family: $headings-font-family;
    border-width: 0px;
}

div.body-container {
    background-color: white;
    padding-bottom: 30px;
}

div.footer-left {
    padding-bottom: 25px;
}

div.footer-links {
    padding-left: 26px;
}

div#footer {
    margin-bottom: 0px;
}

div.footer-links img {
    height: 85px;
    float: left;
    background-color: white;
    border-radius: .5rem;
    padding: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    transition: transform .2s;
}

div.footer-links img:hover {
    transform: scale(1.05);
}

blockquote {
    font-size: 16px;
    background-color: #f8f8f8;
}

div#homeid, div#textid {
    line-height: 1.65;
}

div#textid h3 {
    margin-top: 35px;
}

div#gridid h3 {
    margin-top: 45px;
    margin-bottom: 8px;
}

/* Member pages */
.hugespacer {
    height: 50px;
}

.bigspacer {
    height: 20px;
}

.spacer {
    height: 10px;
}

.smallspacer {
    height: 5px;
}

.titlebox {
    min-width: 300px;
}

.pad-right {
    padding-right: 10px;
}

.pad-left {
    padding-left: 10px;
}

.smallhead {
    font-size: 19px;
    line-height: 19px;
}

img.big-member-photo {
    margin-bottom: 24px; 
    margin-top: 17px; 
    border-radius: 10px 10px 10px 10px;
    max-width: 170px;
}

.member-photo-title {
    margin-top: 24px;
}

.member-photo-title .media-heading {
    font-size: 200%;
    font-weight: bold;
}

.member-photo-title .smallhead {
    font-weight: 200;
}

.post h4 {
    margin-top: 25px;
}

ul.chronological span {
    color: #909090;
}

a.member-link {
    color: #333333;
}

.member-pane .col-sm-12 {
    padding-left: 40px;
    padding-right: 40px;
}

.member-pane .post {
    line-height: 1.65;
}

div#alumni h4 {
    padding-top: 15px;
}

div#alumni h3 {
    margin-top: 15px;
    padding-top: 15px;
}

div#alumni small {
    color: #a0a0a0;
}

@media only screen and (max-width: 768px) {
    #gridid .row {
        margin-bottom: 0px;
    }
}

.member-list-item[data-href]:hover {
    background: #f1f4fc;
    cursor: pointer; 
}
